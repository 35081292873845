const $menuButton = $('header #nav-icon');
const $body = $('body');
const $header = $('header');

function hideMenu() {
  $header.removeClass('open');
  $body.removeClass('no-scroll-y');
}

function showMenu() {
  $body.addClass('no-scroll-y');
  $header.addClass('open');
}

if ($menuButton) {
  $menuButton.click(function () {
    if ($header.hasClass('open')) {
      hideMenu();
    } else {
      showMenu();
    }
  });
}
